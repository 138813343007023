$primary-variant1: #41558D;
$primary-variant2: #0d123c;
$white: #fff;
$gray: #ccc;
$secondary: #4f9b7e;
$gray-variant1: #5f5f5f;
$black-variant1: #0a0a0a;
$white-variant1: #fefefe;



.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24
}

.icon-filled-400 {
  font-variation-settings:
    'FILL' 1,
    'wght' 400
}

.icon-400 {
  font-variation-settings:
    'wght' 400
}

.icon-filled {
  font-variation-settings:
    'FILL' 1
}



.required::after{
  content: '*';
  color: red;
  margin-left: 2px;
}



body {
  font-size: 14px;
  margin: 0;

}

// .text-center {
//   text-align: center !important;
// }

.olol li {
  list-style: disc !important;
}

html,
body,
#root {
  min-height: 100vh;
  font-family: 'DM Sans', sans-serif;
}

.MuiTypography-root,
.MuiGrid-root,
.MuiTabs-root {
  font-family: 'DM Sans', sans-serif !important;
}

.MuiInputAdornment-root.custom-font .MuiTypography-root,
.custom-font {
  font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif !important;
}

.image-cover {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid $gray;
  object-fit: cover;
  object-position: center right;
}

.logo {
  width: 100px;
}

.logo-small {
  width: 60px;
}

.collapsedLogo {
  width: 20px;
  position: relative;
  left: 8px;
}

.menu-item {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background: $white;
}

.menu-item.active {
  background: $secondary;
}

.cursor-pointer {
  cursor: pointer;
}

.fabits-logo {
  width: 150px;
  margin-bottom: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: auto;
  appearance: auto;
  margin: 0;
}

/* Firefox */
.number-input input[type='number'] {
  appearance: auto;
  -moz-appearance: auto;
}
.resend-otp {
  color: $primary-variant1;
  text-decoration: underline;
}

.resend-link {
  color: $primary-variant2;
  font-weight: 500;
  font-size: 18px;
}

/* Datagrid CSS */
.p-datatable {
  .p-datatable-thead > tr > th {
    border-top: 0;
    border-bottom: 0;
    padding: 10px 10px;
  }
  .p-datatable-tbody > tr > td {
    padding: 6px 10px;
  }
}

.p-datatable.light {
  .p-datatable-thead > tr > th {
    color: #333333 !important;
  }
  .p-datatable-tbody > tr > td {
    color: #333333;
  }
  .p-datatable-header {
    color: #333333;
  }
}

.p-datatable.dark {
  border-color: #c9c9c9;
  .p-datatable-thead > tr > th {
    background: #000000 !important;
    border-color: #333333;
    color: #f5f5f5 !important;
  }
  .p-datatable-tbody > tr > td {
    border-color: #333333;
    background: #000000;
    color: #f5f5f5;
  }
  .p-datatable-header {
    background: #000000 !important;
    color: #f5f5f5;
    border: none;
  }
  &.p-datatable-striped {
    .p-datatable-tbody > tr.p-row-odd > td {
      background: #242424;
    }
  }
}

.p-datatable.order-history-table {
  .p-datatable-table {
    width: 100%;
  }
  th {
    text-align: left;
  }
}

.status-disabled {
  pointer-events: none;
  color: $gray !important;
  border-color: $gray !important;
}

.horizontal_dotted_line {
  border-bottom: 1px solid $gray;
  width: 150px;
  margin: 0 10px;
  opacity: 0.5;
}

.hr {
  border-bottom: 1px solid $gray;
  width: 100%;
  margin: 0 10px;
  opacity: 0.5;
}

/* auto fill override css */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}



.success {
  font-weight: 700;
  color: #66bb6a;
}

.failed {
  font-weight: 700;
  color: #ff5252;
}

.tooltip-table,
.tooltip-table tr,
.tooltip-table th,
.tooltip-table td {
  border: 1px solid $white;
  border-collapse: collapse;
}

.tooltip-table td {
  padding: 5px;
}

.apexcharts-yaxis-label,
.apexcharts-xaxis-label {
  fill: #fff;
}

.apexcharts-yaxis-label-white,
.apexcharts-xaxis-label-white {
  fill: #000;
}

.css-9dx17z-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
  color: white !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-t0my2o-MuiButtonBase-root-MuiIconButton-root{
display: none;
}



.loginlogo {
  margin-top: 20px !important;
}

// .signup-container{
//   margin-top: 100px !important;
// }
.password-container {
  margin-top: 30px !important;
  margin-bottom: 10px !important;
}

.text-left {
  text-align: left !important;
}

.login__container {
  margin-bottom: 5rem !important;
}

.margin__top {
  margin-top: 5rem !important;
}

.homemob .css-12hcvai-MuiGrid-root {
  -webkit-box-flex-wrap: initial !important;
  flex-wrap: nowrap !important;
}

.login__footer a {
  color: $primary-variant1 !important;
}

.min__height {
  min-height: calc(65vh - 100px);
}

.MuiAlert-message {
  width: 100%;
}

.login_bg {
  background-image: url('./assets/images/Login_background.png');
  background-repeat: no-repeat;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #333333 !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  box-shadow: none;
  background: #4f9b7e !important;
}

.fa-phone-volume::before {
  background: #fff;
  color: #4f9b7e;
  padding: 5px;
  border-radius: 50%;
}

.fa-phone-volume {
  rotate: -45deg;
}

/* Stepper CSS */

.stepper-wrapper {
  font-family: Arial;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid #fff;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #fff;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 6px;
  border: 1px solid #fff;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  border: 1px solid #4f9b7e;
}

.stepper-item.completed::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #4f9b7e;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.arrowButton {
  position: relative;
  background: #4f9b7e;
  border: 1px solid #4f9b7e;
}
.arrowButton:after,
.arrowButton:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrowButton:after {
  border-color: rgba(79, 155, 126, 0);
  border-top-color: #4f9b7e;
  border-width: 20px;
  margin-left: -20px;
}
.arrowButton:before {
  border-color: rgba(79, 155, 126, 0);
  border-top-color: #4f9b7e;
  border-width: 21px;
  margin-left: -21px;
}

.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  display: none;
}

.buy-breakage {
  .p-datatable {
    .p-datatable-table {
      width: 100%;
      .p-datatable-tbody > tr > td {
        text-align: center;
      }
    }
  }
}

.plan-calculator {
  .p-datatable {
    .p-datatable-table {
      width: 100%;
      .p-datatable-thead > tr > th {
        text-align: left;
      }
    }
  }
}

.siq_bR {
  bottom: 30px !important;
}

.active-ipo-table.MuiPaper-rounded,
.active-gold-bonds-table.MuiPaper-rounded {
  border-radius: 24px;
  
}

// footer classes
.footer-container {
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #1b243b;

  ul {
    padding: 0;
  }

  p,
  h2,
  h1,
  ul,
  b {
    color: #eaeaea;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
    padding: 8px;
  }

  article {
    color: #eaeaea;
    line-height: 22px;
    font-weight: 300;
  }

  .footer-header {
    display: flex;
    justify-content: space-between;
  }

  .nav-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer-logo {
    width: 13%;
  }

  .dashed-line {
    padding: 0px;
    border: 1px dashed #41558d;
    margin: 40px 0px;
  }
}

.contituents {
  div {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .container {
    background-color: rgba(233, 233, 233, 0.1);

    .accordian {
      outline: none;
      border: none;
      box-shadow: none;
      display: flex;
      justify-content: space-around;
      margin: 10px 0;
      padding: 20px 0;
      border-bottom: 1px solid #d3dbec;

      .summary-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .tab-details {
        border-top: 1px dotted #d3dbec;
      }
    }
  }
}

.sip-section {
  .trash-hover,
  .edit-hover {
    display: none;
  }
  &:hover {
    .trash-hover,
    .edit-hover {
      display: block;
    }
  }
}

.trash-hover,
.edit-hover {
  padding: 8px;
  &:hover {
    border-radius: 24px;
  }
}

.trash-hover:hover {
  background: #fff0f0;
}

.edit-hover:hover {
  background: #4f9b7e4d;
}

.MuiButtonBase-root.MuiToggleButton-root.Mui-selected,
.MuiButtonBase-root.MuiToggleButton-root.Mui-selected:hover {
  background-color: #4f9b7e;
  color: #fff;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  padding: 5px 8px !important;
  border-radius: 20px !important;
  margin: 0 1px !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped.insurance-select {
  padding: 0px !important;
  border-radius: 12px !important;
  margin: 0 1px !important;
  border: 2px solid #fff !important;
}

.MuiButtonBase-root.MuiToggleButton-root.insurance-select.Mui-selected,
.MuiButtonBase-root.MuiToggleButton-root.insurance-select.Mui-selected:hover {
  background-color: #eceef4;
  color: #41558d;
  border: 2px solid #41558d !important;
  & .MuiSvgIcon-root {
    color: #41558d;
  }
}

@media only screen and (max-width: 1000px) {
  #unviewedBadge {
    position: absolute !important;
  }
}

@media only screen and (min-width: 1000px) {
  #unviewedBadge {
    position: fixed !important;
  }
}